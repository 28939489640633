import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '@/styles/login.scss';
import { Router } from "@reach/router"
import PurchasePage from "@/components/Purchase";
import ProfilePage from "@/components/Profile";
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '@/libraries/hooks/helper/path';

export default () => {
  const { i18n } = useTranslation();
  return (
    <Layout>
      <SEO title="App" />
      <section className="advertising-policy-banner background-overlay">
        
      </section>
      <Router basepath={getLocalizedPath(i18n.language, '/app')}>
        <PurchasePage path="/purchase" />
        <ProfilePage path="/" />
      </Router>

    </Layout >
  )
};

