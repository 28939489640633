import React, { useEffect, useState } from "react"
import '@/styles/login.scss';
import { useSelector } from 'react-redux';
import { navigate, Link } from "gatsby"

import '@/styles/app.scss';
import firebase from "gatsby-plugin-firebase";
import { getLocalizedPath } from '@/libraries/hooks/helper/path';
import { useTranslation } from 'react-i18next';



// follow this one:
// https://github.com/stripe-samples/checkout-one-time-payments

export default () => {
  const { isLoggedIn, user } = useSelector(state => state.app);
  const { i18n } = useTranslation();

  const [gold, setGold] = useState(-1);
  const [coins, setCoins] = useState(-1);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(getLocalizedPath(i18n.language, '/log-in'));
      return null;
    }
    if (user) {
      firebase
        .firestore()
        .collection('env')
        .doc(`/dev/users/${user.uid}/private/profile`)
        .get()
        .then(doc => {
          if (doc.exists) {
            const { gold, coins } = doc.data();
            setGold(gold);
            setCoins(coins);
          }
        });
    }

  }, [user, setCoins, setGold])

  return (
    <section className="profile">
      {user && (
        <div>
          <h4> Welcome back, {`${user.firstName} ${user.surname}`}!</h4>
          {(gold !== -1 || coins !== -1) &&
            <div className="balance">
              <p>You have <span className="currency">{gold}</span> Gold Coins.</p>
              <p>Are you thinking about creating campaigns?</p>
            </div>}

          <Link className="button" to={getLocalizedPath(i18n.language, '/app/purchase')}> Purchase More </Link>
        </div>
      )}

    </section>);
};

