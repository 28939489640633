import React, { useState } from "react"
import '@/styles/login.scss';
import { useSelector } from 'react-redux';
import { navigate, Link } from "gatsby"
import { StripeContext } from './StripeProvider';
import { useElements, CardElement } from '@stripe/react-stripe-js';
import '@/styles/app.scss';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '@/libraries/hooks/helper/path';
import Grid from '@material-ui/core/Grid';
import FormikTextField from '@/components/CustomTextField';
import { Formik } from 'formik';
import { CircularProgress, Divider } from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutlineRounded";
import CancelRounded from "@material-ui/icons/CancelRounded";

const fetch = require(`node-fetch`)

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "20px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const renderPreview = (amount) => {
  const cost = amount / 100.0;
  const serviceCharge = cost * 0.0352 + 2.35;

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <span>Cost of Gold Coins</span>
        </Grid>
        <Grid item xs={4}>
          <span class="right-align">HK${cost.toFixed(2)}</span>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <span>Service Charges</span>
        </Grid>
        <Grid item xs={4}>
          <span class="right-align">HK${serviceCharge.toFixed(2)}</span>
        </Grid>
      </Grid>
      <div className="vertical-spacer" />
      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <span>Total</span>
        </Grid>
        <Grid item xs={4}>
          <span class="right-align">HK${(cost + serviceCharge).toFixed(2)}</span>
        </Grid>
      </Grid>
    </>);
}

export default () => {
  const elements = useElements();
  const [goldToPurchase, setGoldToPurchase] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [stripeSubmiting, setStripeSubmitting] = useState(false);
  const [finishPurchase, setFinishPurchase] = useState(false);
  const [purchaseError, setPurchaseError] = useState(null);
  const { i18n } = useTranslation();

  const { isLoggedIn, user } = useSelector(state => state.app);
  if (!isLoggedIn) {
    navigate(getLocalizedPath(i18n.language, '/log-in'));
    return null;
  }

  return (
    <StripeContext.Consumer>
      {({ stripe }) => {
        const handleStripeSubmit = async (event) => {
          event.preventDefault();
          event.stopPropagation();

          setStripeSubmitting(true);
          stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: `${user.firstName} ${user.surname}`,
              },
            }
          }).then(res => {
            if (res.error) {
              throw res.error;
            }
            setStripeSubmitting(false);
            setFinishPurchase(true);
          }).catch(error => {
            setPurchaseError(error.message);
            setFinishPurchase(true);
            setStripeSubmitting(false);
          })
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `error.message`.
        };

        if (purchaseError) {
          return (
            <div className="payment-failed">
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <CancelRounded />
              <h4>Payment failed</h4>
              <p class="break-word">{purchaseError}</p>
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <p>Please contact us at <a href="mailto:help@yitnow.com">help@yitnow.com</a></p>
            </div>
          );
        }

        if (!clientSecret) {
          return (
            <section className="profile">
              <div>
                <h3>Buy Gold Coins</h3>
                <ul>
                  <li>Price is HK$1.00 per 100 Gold Coins</li>
                  <li>Minimum purchase is 1000 Gold Coins</li>
                  <li>Payment Fees are added to the total</li>
                </ul>
                <Formik
                  initialValues={{ amount: 1000 }}
                  validate={values => {
                    const errors = {};
                    if (!values.amount) {
                      errors.amount = 'Please input valid amount';
                    } else if (values.amount < 1000) {
                      errors.amount = 'Minium purchase is 1000 Gold Coins';
                    } else if (values.amount > 1000000) {
                      errors.amount = 'Maximum purchase is 1000000 Gold Coins';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setGoldToPurchase(values.amount);
                    fetch(`${process.env.GATSBY_API_ENDPOINT}/profile/customer/paymentIntent?key=${process.env.GATSBY_API_KEY}`,
                      {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${user.token}`
                        },
                        body: JSON.stringify({
                          "coins": values.amount,
                          "currency": "HKD",
                          "description": "test",
                          "uid": user.uid
                        })
                      }).then(res => {
                        return res.json()
                      }).then(res => {
                        if (!res.clientSecret) {
                          throw new Error(res.message || 'Unexpected internal error');
                        }
                        const { clientSecret: cs } = res;
                        setClientSecret(cs);
                        setSubmitting(false);
                      }).catch(error => {
                        console.error(error);
                        setPurchaseError(error.message);
                        setSubmitting(false);
                      })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                      <form onSubmit={handleSubmit} className="card-form">
                        <p>
                          I'd like to get
                          <FormikTextField
                            name="amount"
                            label="Number Of Coins"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "1000", max: "10000000", step: "100" }}
                          /> more Gold Coins.
                        </p>
                        <div className="vertical-spacer" />
                        {renderPreview(values.amount)}
                        {!isSubmitting ?
                          <button type="submit">
                            Continue
                          </button> :
                          <button disabled>
                            <CircularProgress size={20} color="primary" />
                          </button>
                        }
                      </form>

                    )}
                </Formik>
              </div>
            </section>)
        }


        if (finishPurchase) {
          return (
            <div className="payment-successful">
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <CheckCircleOutline />
              <h4>Payment successful</h4>
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <Link to={getLocalizedPath(i18n.language, '/log-in')}>
                Check My Profile
              </Link>
            </div>
          );
        }

        return (
          <section className="profile">
            <div className="card-form">
              <h6>Checkout</h6>
              <p>Purchasing <span>{goldToPurchase}</span> Gold Coins for:</p>
              {renderPreview(goldToPurchase)}
              <div className="vertical-spacer" />
              <div className="vertical-spacer" />
              <form onSubmit={handleStripeSubmit}>
                <label>
                  Card details
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                </label>
                <div className="vertical-spacer" />
                {!stripeSubmiting ?
                  <button type="submit">
                    Checkout
                  </button> :
                  <button disabled>
                    <CircularProgress size={20} color="primary" />
                  </button>
                }
              </form>
            </div>

          </section>);
      }}
    </StripeContext.Consumer >
  )
};

